import Button from "@mui/material/Button";
//@ts-ignore
import { jsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from "react";

import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { format } from "date-fns";
import Api from "../../../../Api";
import DatesDataTable from "./DatesDataTable";
import EmpDataTable from "./EmpDataTable";
import TrainingInfo from "./TrainingInfo";
// import ProjectProgress from "./ProjectProgress";

export const projectStatusOptions = [
  { value: "OT", text: "Satisfactory", bgColor: "#28A745", color: "#fff" },
  {
    value: "U",
    text: "Partially satisfactory",
    bgColor: "#FFC106",
    color: "#333",
  },
  { value: "P", text: "Problematic", bgColor: "#DD3444", color: "#fff" },
];

interface Props {
  open: boolean;
  onClose: () => void;
  selectedRow: any;
}

const TrainingPdf = ({ open, onClose, selectedRow }: Props) => {
  const componentRef = useRef<any>();
  const step1Ref = React.useRef<HTMLDivElement>(null);
  const step2Ref = React.useRef<HTMLDivElement>(null);
  const step3Ref = React.useRef<HTMLDivElement>(null);

  const createPdfFromHtml = () => {
    const pdf_page = document.getElementById("pdf_page");

    if (pdf_page) {
      var pdf = new jsPDF("l", "mm", [1900, 1410]);

      pdf.html(pdf_page, {
        callback(doc: any) {
          console.log(doc.internal.pageSize.width);
          let title: string = selectedRow.training_name.replaceAll(" ", "_");
          title = title.slice(0, 30);
          doc.save(`${title}-${format(new Date(), "MMM-dd-yyyy")}.pdf`);
        },
        autoPaging: "text",
        x: 5,
        y: 5,
        margin: [5, 5, 5, 5],
      });
    }
  };

  const [savedEmps, setSavedEmps] = useState<any[]>([]);
  const [savedDates, setSavedDates] = useState<any[]>([]);

  useEffect(() => {
    if (selectedRow && open) {
      getTraingdataById(selectedRow?.id);
    }
  }, [selectedRow, open]);

  const getTraingdataById = (training_id: number) => {
    Api.getList(`ead/training/${training_id}/all/all/all/`)
      .then((data) => {
        if (
          data &&
          data?.trainingEmployee &&
          data?.trainingEmployee?.length > 0
        ) {
          const training_emps = data?.trainingEmployee;
          setSavedEmps(training_emps);
          // handleEditData(training_emps[0]);
        }
        if (
          data &&
          data?.trainingEmployee &&
          data?.trainingEmployee?.length > 0
        ) {
          const training_emps = data?.trainingDates;
          setSavedDates(training_emps);
          // handleEditData(training_emps[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTraingDatesById = (training_id: number) => {
    Api.getList(`ead/training/${training_id}/all/all/all/`)
      .then((data) => {
        if (
          data &&
          data?.trainingEmployee &&
          data?.trainingEmployee?.length > 0
        ) {
          const training_emps = data?.trainingDates;
          setSavedEmps(training_emps);
          // handleEditData(training_emps[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        style={{ width: "100vw" }}
        fullScreen
      >
        <DialogContent style={{ width: "100vw", padding: 0 }}>
          <div id="pdf_page" ref={componentRef}>
            <div ref={step1Ref} style={{ display: "block", padding: 10 }}>
              <TrainingInfo data={selectedRow} />
            </div>
            {!!savedEmps.length && (
              <div
                ref={step2Ref}
                style={{ display: "block", padding: "0px 10px" }}
              >
                <h4
                  style={{
                    fontSize: 22,
                    fontWeight: 700,
                    marginTop: 20,
                    marginBottom: 8,
                  }}
                >
                  Training Employees
                </h4>
                <EmpDataTable rows={savedEmps} />
              </div>
            )}

            {savedDates.length > 0 && (
              <div
                ref={step3Ref}
                style={{
                  display: "block",
                  padding: "0px 10px",
                  paddingBottom: 30,
                }}
              >
                <h4
                  style={{
                    fontSize: 22,
                    fontWeight: 700,
                    marginTop: 20,
                    marginBottom: 8,
                  }}
                >
                  Training Dates
                </h4>
                <DatesDataTable rows={savedDates} />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button onClick={createPdfFromHtml} autoFocus>
            Save as PDF
          </Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TrainingPdf;
