import { Avatar, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import logo from "../../../../static/img/logo.svg";

export const projectStatusOptions = [
  { value: "OT", text: "Satisfactory", bgColor: "#28A745", color: "#fff" },
  {
    value: "U",
    text: "Partially satisfactory",
    bgColor: "#FFC106",
    color: "#333",
  },
  { value: "P", text: "Problematic", bgColor: "#DD3444", color: "#fff" },
];

export interface ProjectInfo {
  description: string;
  title: string;
  imp_agency: string;
  exec_agency: string;
  project_id: string;
  amount: number;
  disbursed: number;
  un_disbursed: number;
  signing_date: string;
  closing_date: string;
  status: string;
  donor: string;
  sub_donor: string;
  extended_closing_date: string;
  be_fin_year: any;
  be_fin_year_value: any;
  extended_closing_date_1: string;
  extended_closing_date_2: string;
  comments: string;
}

interface InfoEditorProps {
  data: any;
}

const TrainingInfo = ({ data }: InfoEditorProps) => {
  const companyInfo = useSelector((state: any) => state.base.companyInfo);
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          marginBottom: "30px",
        }}
      >
        <Avatar
          src={logo}
          alt="logo"
          style={{
            width: 70,
            height: 70,
            marginRight: "8px",
            position: "absolute",
            top: 10,
            left: 10,
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "green", fontSize: "32px", marginTop: "15px" }}
          >
            {companyInfo?.company_name}
          </Typography>
        </Box>
      </Box>
      <div>
        <Typography
          variant="h4"
          style={{
            fontSize: "24px",
            textAlign: "center",
            color: "#fff",
            backgroundColor: "#28A745",
            padding: "10px",
            letterSpacing: "4px",
            fontWeight: "bold",
            borderRadius: "7px",
          }}
        >
          {data?.training_name}
        </Typography>
      </div>

      <div
        style={{
          // background: "#ddd",
          padding: "5px 20px",
          border: "2px solid #333",
        }}
      >
        <Box>
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                      lineHeight: "30px",
                    }}
                  >
                    Training Remarks:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "20px", marginLeft: 27 }}
                  >
                    <strong>{data?.remarks}</strong>
                  </h5>
                </td>
              </tr>
              <tr>
                <td style={{ height: 20 }}></td>
              </tr>
              <tr>
                <td style={{ padding: "6px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                      lineHeight: "30px",
                    }}
                  >
                    Development Partner:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "24px", marginLeft: 20 }}
                  >
                    <strong>{data?.dev_partner_name}</strong>
                  </h5>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "6px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Sector Name:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "24px", marginLeft: 20 }}
                  >
                    {" "}
                    <strong>{data?.sector_name}</strong>
                  </h5>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "6px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Section Name:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "24px", marginLeft: 20 }}
                  >
                    {" "}
                    <strong>{data?.section_name}</strong>
                  </h5>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "6px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Training Year:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "24px", marginLeft: 20 }}
                  >
                    {" "}
                    <strong>{data?.training_year}</strong>
                  </h5>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "6px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Training Type:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "24px", marginLeft: 20 }}
                  >
                    {" "}
                    <strong>{data?.training_type}</strong>
                  </h5>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "6px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Start Date:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "24px", marginLeft: 20 }}
                  >
                    {" "}
                    <strong>{data?.start_date}</strong>
                  </h5>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "6px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    End Date:
                  </h1>
                </td>
                <td>
                  <h5
                    style={{ margin: "0px", fontSize: "24px", marginLeft: 20 }}
                  >
                    {" "}
                    <strong>{data?.end_date}</strong>
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </div>
    </Box>
  );
};

export default TrainingInfo;
