import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useAppSelector } from "../../store";
import GlobalFilter from "./GlobalFilter";

function FtcTrainingTable({
  data,
  columns,
  showHeader = true,
  totalEmployees = [],
  setSelectedRow,
}) {
  const navigate = useNavigate();
  const userInfo = useAppSelector((state) => state.auth.userInfo);

  const matcheSM = useMediaQuery("(max-width:767px)");
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 50 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {showHeader && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            flexDirection: matcheSM ? "column" : "row",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
            }}
          >
            <Typography
              variant="h5"
              style={{ color: "blue", fontSize: matcheSM ? "16px" : "20px" }}
            >
              LIST OF TRAININGS:
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: matcheSM ? "12px" : "18px",
                fontWeight: "600",
              }}
            >
              (Total Trainings : {rows.length}) (Total Employees :{" "}
              {totalEmployees.length})
            </Typography>
          </div>
          <div style={{ textAlign: "right" }}>
            <hr />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: 20,
              }}
            >
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/add-training")}
                style={{ height: "auto", marginBottom: 5 }}
              >
                Add Training
              </Button>
            </div>
          </div>
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          overflow: "auto",
          "& th": {
            padding: "2px 8px",
          },
          "& td": {
            padding: "4px 8px",
            fontSize: "12px",
          },
        }}
      >
        <MaUTable {...getTableProps()}>
          <TableHead
            style={{
              background: "green",
              color: "#fff",
              paddingTop: "5px",
              paddingBottom: 5,
            }}
          >
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: column.id === "title" ? 230 : "auto",
                      }}
                    >
                      <span style={{ color: "#fff", fontSize: 16 }}>
                        {column.render("Header")}
                      </span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <KeyboardArrowDown style={{ color: "#fff" }} />
                          ) : (
                            <KeyboardArrowUp style={{ color: "#fff" }} />
                          )
                        ) : (
                          <KeyboardArrowDown style={{ color: "#fff" }} />
                        )}
                      </span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page?.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() => {
                    setSelectedRow(row);
                    // matcheSM
                    //     ? navigate(
                    //         `/manage_project?pid=${
                    //           row.original?.id || row.original?.pid__id
                    //         }&dev_partner=${row.original?.dev_partner}`
                    //       )
                    //     : window.open(
                    //         `/manage_project?pid=${
                    //           row.original?.id || row.original?.pid__id
                    //         }&dev_partner=${row.original?.dev_partner}`,
                    //         "_blank"
                    //       );
                  }}
                  sx={{
                    "&:hover": {
                      background: "#eee",
                    },
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        <span
                          style={{
                            color: "#333",
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                        >
                          {cell.render("Cell")}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          flexDirection: matcheSM ? "column" : "row",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
            <KeyboardArrowRightIcon />
          </IconButton>{" "}
          <IconButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>{" "}
          <Typography variant="body1" style={{ minWidth: "max-content" }}>
            Page:{parseInt(pageIndex + 1)} of {parseInt(pageOptions.length)}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <span style={{ minWidth: "max-content" }}>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px", padding: "3px 10px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ padding: "5px 10px" }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default FtcTrainingTable;
