import { createSlice } from "@reduxjs/toolkit";

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    donor: "All",
    region: "All",
    sector: "All",
    donortype: "All",
  },
  reducers: {
    setFilterParams: (state, action) => {
      state.donor = action.payload["donor"] || "All";
      state.region = action.payload["region"] || "All";
      state.sector = action.payload["sector"] || "All";
      state.donortype = "All";
    },
  },
});
// sector/region/donar

export const dashboardActions = DashboardSlice.actions;

export const dashboardReducer = DashboardSlice.reducer;
