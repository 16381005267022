import { Box, IconButton, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Api, { APIs } from "../../../Api";
//import CommonUtils from "../../base/utils/CommonUtils";
import { Edit } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FtcTrainingTable from "../../../components/FtcTrainingTable";
import { useAppSelector } from "../../../store";
import SearchTrainingForm from "./TrainingComp/SearchTrainingForm";
import TrainingPdf from "./TrainingPdf";

const donortypeOptions = ["All", "Bi-lateral", "Multi-lateral"];
const FtcTrainingGrid = () => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState([]);

  const userInfo = useSelector((state) => state.auth.userInfo);

  const [loader, setloader] = useState(false);

  const getSearchProjects = () => {
    var params = {
      id: "all",
      type: "all",
      year: "all",
      country: "all",
    };

    setloader(true);
    Api.getList(APIs.EAD_SEARCH_TRAINING, params)
      .then((payload) => {
        if (payload?.training && payload?.training?.length > 0) {
          const p = payload.training?.map((item, idx) => ({
            ...item,
            sr_num: idx + 1,
            employees: payload?.trainingEmployee?.filter(
              (emp) => emp?.training === item?.id
            ),
          }));
          setRows(p);
          setTotalEmployees(payload?.trainingEmployee);
        }
        setloader(false);
      })
      .catch((e) => {
        setloader(false);
      });
  };

  const getSearchTraining = (data: any) => {
    var params = {
      devpartner: data?.dev_partner_name || "all",
      type: data?.training_type || "all",
      year: data?.training_year || "all",
      country: data?.country || "all",
      sector: data?.sector_name || "all",
      startdate: data?.start_date || "all",
      enddate: data?.end_date || "all",
    };

    setloader(true);
    Api.getList(APIs.EAD_SEARCH_TRAINING_RANGE, params)
      .then((payload) => {
        if (payload?.training && payload?.training?.length > 0) {
          const p = payload.training?.map((item, idx) => ({
            ...item,
            sr_num: idx + 1,
            employees: payload?.trainingEmployee?.filter(
              (emp) => emp?.training === item?.id
            ),
          }));
          setRows(p);
          setTotalEmployees(payload?.trainingEmployee);
        }
        setloader(false);
      })
      .catch((e) => {
        setloader(false);
      });
  };

  useEffect(() => {
    if (columns.length === 0) {
      getSearchProjects();
    }
  }, [columns]);

  const getTextColor = (val) => {
    if (val === "Problematic") {
      return "red";
    } else if (val === "Satisfactory") {
      return "green";
    } else {
      return "orange";
    }
  };

  const Columns = React.useMemo(
    () => [
      {
        accessor: "sr_num",
        id: "sr_num",
        Header: () => (
          <strong style={{ width: 50, whiteSpace: "nowrap" }}>Sr#</strong>
        ),
      },
      {
        accessor: "training_year",
        id: "training_year",
        Header: () => <strong>Year</strong>,
      },
      {
        accessor: "training_type",
        id: "training_type",
        Header: () => <strong>Type</strong>,
        style: {
          width: 100,
        },
      },
      {
        accessor: "section_name",
        id: "section_name",
        Header: () => <strong>Section Name</strong>,
        style: {
          width: 100,
        },
      },
      {
        accessor: "dev_partner_name",
        id: "dev_partner_name",
        Header: () => <strong>Dev Partner</strong>,
      },
      {
        accessor: "training_name",
        id: "training_name",
        Header: () => <strong>Name of Training Program</strong>,
      },
      {
        accessor: "employees",
        id: "employees",
        Header: () => <strong>Employees</strong>,
        Cell: (row) => {
          const empList = row?.cell?.value;
          return (
            <div>
              {empList?.map((emp) => (
                <p>{emp?.employee_name}</p>
              ))}
            </div>
          );
        },
      },
      {
        accessor: "country",
        id: "country",
        Header: () => <strong>Country</strong>,
      },
      {
        accessor: "start_date",
        id: "start_date",
        Header: () => <strong>Start Date</strong>,
        Cell: (row) => {
          return (
            <span>{format(new Date(row.cell.value), "dd MMM, yyyy")}</span>
          );
        },
      },
      {
        accessor: "end_date",
        id: "end_date",
        Header: () => <strong>End Date</strong>,
        Cell: (row) => {
          return (
            <span>{format(new Date(row.cell.value), "dd MMM, yyyy")}</span>
          );
        },
      },

      {
        accessor: "confirmd",
        id: "confirmd",
        Header: () => <strong>Confirmed</strong>,
      },
      {
        accessor: "id",
        id: "id",
        Header: () => <strong>Action</strong>,
        Cell: (row) => {
          return (
            <Link to={`/add-training?training_id=${row?.cell?.value}`}>
              <IconButton>
                <Edit style={{ fontSize: 16 }} />
              </IconButton>
            </Link>
          );
        },
      },
    ],
    []
  );
  const data = React.useMemo(() => rows, []);

  const isSuperuser = useAppSelector(
    (state) => state.auth.userInfo?.isSuperuser
  );
  const assignedProjects = useAppSelector((state) => state.auth.userProjects);
  const isAnabledRow = (row) => {
    const isDevPartner = row.dev_partner?.includes(userInfo?.donorName);

    const isAssigned = assignedProjects.some(
      (p) => p.pid === (row.id || row.pid__id)
    );
    if (isSuperuser || isAssigned || isDevPartner) {
      return true;
    } else {
      return false;
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);

  return (
    <React.Fragment>
      <TrainingPdf
        open={showPdfModal}
        onClose={() => setShowPdfModal(false)}
        selectedRow={selectedRow}
      />
      <Box
        component="form"
        sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
      >
        {data !== null ? (
          <SearchTrainingForm getParams={(data) => getSearchTraining(data)} />
        ) : (
          <Box sx={{ padding: "0px 20px" }}>
            <div className="row">
              <div className="col-md-12">
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </div>
              <div className="col-md-12 col-lg-4" style={{ marginTop: 10 }}>
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </div>
              <div className="col-md-12 col-lg-4" style={{ marginTop: 10 }}>
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </div>
              <div className="col-md-12 col-lg-4" style={{ marginTop: 10 }}>
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </div>
              <div className="col-md-12 col-lg-4" style={{ marginTop: 10 }}>
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </div>
              <div className="col-md-12 col-lg-4" style={{ marginTop: 10 }}>
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              </div>
              <div
                className="col-md-12 col-lg-4"
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <Skeleton variant="rectangular" width={"100px"} height={60} />
              </div>
            </div>
          </Box>
        )}
      </Box>
      {loader ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: 10,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <FtcTrainingTable
          data={rows}
          columns={Columns}
          totalEmployees={totalEmployees}
          setSelectedRow={(data) => {
            setSelectedRow(data?.original);
            setShowPdfModal(true);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default FtcTrainingGrid;
