import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

interface Props {
  optionList: OptionType[];
  label: string;
  control: any;
  name: string;
}

interface OptionType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<OptionType>();

export default function ComboBoxAutocomplete({
  optionList,
  label,
  name,
  control,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={(props) => (
        <Autocomplete
          {...props}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              props.field.onChange({
                title: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              props.field.onChange({
                title: newValue.inputValue,
              });
            } else {
              props.field.onChange(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id={label}
          options={optionList}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          sx={{ width: "100%" }}
          freeSolo
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      )}
    />
  );
}
