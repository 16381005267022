import { Paper } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseActions } from "../../base/models/BaseSlice";
import FtcTrainingGrid from "../components/FtcTrainingGrid";

const FtcTraining = () => {
  const dispatch = useDispatch();
  const contentDivHeight = useSelector(
    (state: any) => state.base.contentDivHeight
  );
  const headerHeight = 0;
  const rowPadding = 12;
  const paperHeight = contentDivHeight - 2.5 * rowPadding - headerHeight - 5;
  dispatch(baseActions.setPaperHeight(paperHeight));

  return (
    <React.Fragment>
      <div>
        <div>
          <Paper elevation={6} style={{ padding: 10, height: "100%" }}>
            <FtcTrainingGrid />
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FtcTraining;
