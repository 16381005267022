import { CircularProgress } from "@mui/material";
import * as React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { baseActions } from "../models/BaseSlice";

// import Dashboard from "../../dashboard/containers/Dashboard";
// import Projects from "../../project/containers/Projects";
// import SearchProjects from "../../project/containers/SearchProjects";
// import Organogram from "../../project/containers/Organogram";
// import CompareDashboard from "../../dashboard/containers/CompareDashboard";
// import ProjectDelays from "../../project/containers/ProjectDelays";
// import ProjectEditor from "../../project/containers/ProjectEditor";
import AddTraining from "../../project/components/AddTraining";
import FtcTraining from "../../project/containers/ftc";
import DialogBox from "./DialogBox";
import PrivateRoute from "./PrivateRoute";

// import NewsFeed from "../../dashboard/containers/NewsFeed";

const Login = React.lazy(() => import("../../../pages/Login"));
const HomePage = React.lazy(() => import("../../home"));
const Dashboard = React.lazy(
  () => import("../../dashboard/containers/Dashboard")
);
const ADBDashboard = React.lazy(
  () => import("../../dashboard_adb/containers/Dashboard")
);
const Projects = React.lazy(() => import("../../project/containers/Projects"));
const SearchProjects = React.lazy(
  () => import("../../project/containers/SearchProjects")
);
const SearchJmcs = React.lazy(
  () => import("../../project/containers/SearchJmcs")
);
const SearchAdbJmcs = React.lazy(
  () => import("../../project/containers/SearchAdbJmcs")
);
const Organogram = React.lazy(
  () => import("../../project/containers/Organogram")
);
const CompareDashboard = React.lazy(
  () => import("../../dashboard/containers/CompareDashboard")
);
const ProjectDelays = React.lazy(
  () => import("../../project/containers/ProjectDelays")
);
const ProjectEditor = React.lazy(
  () => import("../../project/containers/ProjectEditor")
);
const JmcDetails = React.lazy(
  () => import("../../project/containers/JmcDetails")
);

const NewsFeed = React.lazy(
  () => import("../../dashboard/containers/NewsFeed")
);
const ProjectContents = React.lazy(
  () => import("../../project/containers/ProjectContents")
);
const BigDisburses = React.lazy(() => import("../../../pages/BigDisburses"));

export const dialogBoxRef = React.createRef<DialogBox>();

const ContentRouter = () => {
  const location = useLocation();
  const topAppbarHeight = useAppSelector(
    (state: any) => state.base.topAppbarHeight
  );

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const dashboardHeight =
      document.getElementById("dashboard-div")?.clientHeight || 0;
    const h =
      dashboardHeight > topAppbarHeight
        ? dashboardHeight - topAppbarHeight
        : document.body.clientHeight;
    dispatch(baseActions.setContentDivHeight(window.innerHeight));
  }, [topAppbarHeight, location.pathname]);

  return (
    <div id={"content-div"} /*style={{ height: height, maxHeight: height }}*/>
      <React.Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          {/* <Route path="login" element={<PublicRoute />}> */}
          <Route path="/" element={<Login />} />
          {/* </Route> */}

          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<HomePage />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="dashboard-adb" element={<ADBDashboard />} />
            <Route path="projects" element={<Projects />} />

            <Route path="searchprojects" element={<SearchProjects />} />
            <Route path="ftc-training" element={<FtcTraining />} />
            <Route path="add-training" element={<AddTraining />} />
            <Route path="search-jmcs" element={<SearchJmcs />} />
            <Route path="search-jc-adb" element={<SearchAdbJmcs />} />

            <Route path="organogram" element={<Organogram />} />

            <Route path="projectInfo" element={<ProjectContents />} />

            <Route
              path="project_physical_progress"
              element={<ProjectContents />}
            />

            <Route path="photo_gallery" element={<ProjectContents />} />

            <Route path="compare" element={<CompareDashboard />} />

            <Route path="delays" element={<ProjectDelays />} />

            <Route path="manage_project" element={<ProjectEditor />} />
            <Route path="jmc-details" element={<JmcDetails />} />

            <Route path="news" element={<NewsFeed />} />
            <Route path="potential-big-disbursers" element={<BigDisburses />} />
          </Route>
          {/* <Route path="*" element={<MissingRoute />} /> */}
        </Routes>
      </React.Suspense>
      <DialogBox ref={dialogBoxRef} />
    </div>
  );
};

function MissingRoute() {
  // pathname can be change based on what url you want to redirect to
  return <Navigate to={{ pathname: "/dashboard" }} />;
}

export default ContentRouter;
