// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block__install {
  display: none;
}
@media (max-width: 768px) {
  .block__install {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 999999;
    background: #fff;
    padding: 15px;
    box-shadow: 2px 3px 5px #ccc;
  }
  .block__install .inner {
    display: flex;
    align-items: center;
  }
  .block__install .inner .close {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .block__install .inner .close img {
    width: 32px;
    height: 32px;
  }
  .block__install .inner .logo {
    width: 48px;
  }
  .block__install .inner .logo img {
    width: 42px;
    border-radius: 2px;
  }
  .block__install .inner .name {
    padding-left: 10px;
  }
  .block__install .inner .name span {
    display: block;
  }
  .block__install .inner .name span.title {
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 600;
  }
  .block__install .inner .cta {
    margin-left: auto;
  }
  .block__install.is-active {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AddToHomeScreen/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE;IACE,eAAe;IACf,WAAW;IACX,SAAS;IACT,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,4BAA4B;EAC9B;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,cAAc;EAChB;EACA;IACE,mBAAmB;IACnB,cAAc;IACd,gBAAgB;EAClB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":[".block__install {\r\n  display: none;\r\n}\r\n@media (max-width: 768px) {\r\n  .block__install {\r\n    position: fixed;\r\n    bottom: 0px;\r\n    left: 0px;\r\n    width: 100%;\r\n    z-index: 999999;\r\n    background: #fff;\r\n    padding: 15px;\r\n    box-shadow: 2px 3px 5px #ccc;\r\n  }\r\n  .block__install .inner {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  .block__install .inner .close {\r\n    width: 32px;\r\n    height: 32px;\r\n    line-height: 32px;\r\n  }\r\n  .block__install .inner .close img {\r\n    width: 32px;\r\n    height: 32px;\r\n  }\r\n  .block__install .inner .logo {\r\n    width: 48px;\r\n  }\r\n  .block__install .inner .logo img {\r\n    width: 42px;\r\n    border-radius: 2px;\r\n  }\r\n  .block__install .inner .name {\r\n    padding-left: 10px;\r\n  }\r\n  .block__install .inner .name span {\r\n    display: block;\r\n  }\r\n  .block__install .inner .name span.title {\r\n    font-size: 1.125rem;\r\n    line-height: 1;\r\n    font-weight: 600;\r\n  }\r\n  .block__install .inner .cta {\r\n    margin-left: auto;\r\n  }\r\n  .block__install.is-active {\r\n    display: block;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
