import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import { useAppSelector } from "../../../store";

const PrivateRoute = () => {
  const isAuth = useAppSelector((state) => state.auth.accessToken);
  let location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <Navbar />
      <Outlet />
    </div>
  );
};

export default PrivateRoute;
