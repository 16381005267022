import { LocalLibrary, Logout, Person } from "@mui/icons-material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import {
  Avatar,
  Box,
  Button,
  Card,
  ClickAwayListener,
  Divider,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import AssignedProject from "../../modals/AssignedProject";
import { logoutUser } from "../../modules/auth/models/AuthSlice";
import { useAppDispatch, useAppSelector } from "../../store";

const ManageOptions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openManageModal, setOpenManageModal] = React.useState(false);
  const currentUser = useAppSelector((state) => state.auth.userInfo);
  const groups = useAppSelector((state) => state.auth.userInfo?.groups);

  const [visible, setVisible] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    //@ts-ignore
    dispatch(logoutUser());
    handleClose();

    setVisible(false);
    navigate("/");
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {openManageModal && (
        <AssignedProject
          open={openManageModal}
          handleClose={() => setOpenManageModal(false)}
        />
      )}

      <Button
        sx={{
          color: "#222",
          fontWeight: "bold",
          borderRadius: 0,
          padding: "0px 5px",
          marginLeft: "20px",
        }}
        startIcon={<ManageHistoryIcon />}
        variant="text"
        onClick={() => setVisible(true)}
        aria-controls="account-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        Manage
      </Button>
      {visible && (
        <ClickAwayListener onClickAway={() => setVisible(false)}>
          <Card
            sx={{
              position: "absolute",
              top: "calc(100% + 23px)",
              right: "0px",
              minWidth: "250px",
            }}
          >
            <Box
              sx={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar>
                <Person />
              </Avatar>
              <Typography
                color="green"
                variant="h6"
                sx={{ marginLeft: "10px" }}
              >
                {currentUser?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color="green"
                variant="body1"
                sx={{ marginLeft: "10px" }}
              >
                (<strong>{groups?.join(", ")}</strong>)
              </Typography>
            </Box>
            <Divider />
            <MenuItem
              onClick={() => {
                setOpenManageModal(true);
                setVisible(false);
              }}
            >
              <ListItemIcon>
                <LocalLibrary fontSize="small" />
              </ListItemIcon>
              Assigned Projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  navigate("/delays");
                }, 100);
                setVisible(false);
              }}
            >
              <ListItemIcon>
                <LocalLibrary fontSize="small" />
              </ListItemIcon>
              Delayed Projects
            </MenuItem>
            {groups?.includes("SPECIAL ADMIN") && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    navigate("/potential-big-disbursers");
                  }, 100);
                  setVisible(false);
                }}
              >
                <ListItemIcon>
                  <LocalLibrary fontSize="small" />
                </ListItemIcon>
                Potential Big Disbursers
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Card>
        </ClickAwayListener>
      )}

      {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar>
            <Person />
          </Avatar>
          <Typography color="green" variant="h6">
            {currentUser?.name}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setOpenManageModal(true)}>
          <ListItemIcon>
            <LocalLibrary fontSize="small" />
          </ListItemIcon>
          Assigned Projects
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setTimeout(() => {
              navigate("/delays");
            }, 100);
          }}
        >
          <ListItemIcon>
            <LocalLibrary fontSize="small" />
          </ListItemIcon>
          Delayed Projects
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu> */}
    </div>
  );
};

export default ManageOptions;
