import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api, { APIs } from "../../Api";
import { setUserProjects } from "../../modules/auth/models/AuthSlice";
import { useAppDispatch } from "../../store";

interface Props {
  open: boolean;
  handleClose: () => void;
}

function AssignedProject({ open, handleClose }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matcheSM = useMediaQuery("(max-width:767px)");
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const userProjects: any[] = useSelector(
    (state: any) => state.auth.userProjects
  );

  React.useEffect(() => {
    if (open) {
      Api.get(APIs.EAD_USER_PROJECTS)
        .then((payload) => {
          if (payload) {
            //console.log("payload", payload)
            dispatch(setUserProjects(payload));
          }
        })
        .catch((err) => {
          console.log(err);
          //@ts-ignore
          dispatch(setUserProjects([]));
        });
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>Assigned Projects</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <List>
              {userProjects && userProjects.length > 0 ? (
                userProjects.map((project) => (
                  <ListItem
                    disablePadding
                    key={project.pid}
                    onClick={() => {
                      navigate(`/manage_project?pid=${project.pid}`);
                      handleClose();
                    }}
                  >
                    <ListItemButton
                      style={{
                        color: "blue",
                        fontSize: matcheSM ? 12 : 14,
                      }}
                    >
                      <ListItemIcon>
                        <AccountTreeIcon />
                      </ListItemIcon>
                      <ListItemText primary={project?.title} />
                    </ListItemButton>
                  </ListItem>
                ))
              ) : (
                <Typography>You don't have any assigned projects</Typography>
              )}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AssignedProject;
