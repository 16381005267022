import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Api, { APIs } from "../../../../Api";
import { FormDateInput } from "./FormDateInput";
import { FormInputDropdown } from "./FormInputDropdown";
import { FormInputText } from "./FormInputText";

import { FormNumberInput } from "./FormNumberInput";

export type IFormInput = {
  training_year: number | null;
  training_type: string;
  dev_partner_name: string;
  country: string;
  sector_name: string;
  start_date: string;
  end_date: string;
};

interface Props {
  getParams: (data: any) => void;
}

function SearchTrainingForm({ getParams }: Props) {
  const { handleSubmit, control } = useForm<IFormInput>({
    defaultValues: {
      training_year: null,
      training_type: "",
      dev_partner_name: "",
      country: "",
      sector_name: "",
      start_date: "",
      end_date: "",
    },
  });

  const [donorOptions, setDonorOptions] = useState<any[]>([]);
  const [sectorOptions, setSectorOptions] = useState<any[]>([]);

  useEffect(() => {
    Api.get(APIs.EAD_UNIQUE_LIST).then((payload: any) => {
      if (payload?.donor && payload?.donor?.length > 0) {
        const donorList = payload?.donor?.map((item: string) => ({
          label: item,
          value: item,
        }));
        setDonorOptions(donorList);
      }
      if (payload?.sector && payload?.sector?.length > 0) {
        const sectorList = payload?.sector?.map((item: string) => ({
          label: item,
          value: item,
        }));
        setSectorOptions(sectorList);
      }
    });
  }, []);

  const onSubmit = (data: IFormInput) => {
    getParams(data);
  };

  return (
    <div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <FormNumberInput
              name="training_year"
              control={control}
              label="Training Year"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormInputText name="country" control={control} label="Country" />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <FormDateInput
              name="start_date"
              control={control}
              label="Start Date"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormDateInput name="end_date" control={control} label="End Date" />
          </Grid>

          {/* dropdown  type*/}
          <Grid item xs={12} md={6} lg={3}>
            <FormInputDropdown
              label="Type"
              name="training_type"
              options={[
                {
                  label: "Course",
                  value: "Course",
                },
                {
                  label: "Doctoral",
                  value: "Doctoral",
                },
                {
                  label: "Master",
                  value: "Master",
                },
                {
                  label: "Master & Doctoral",
                  value: "Master & Doctoral",
                },
                {
                  label: "Online & Physical Course",
                  value: "Online & Physical Course",
                },
                {
                  label: "Online Course",
                  value: "Online Course",
                },
                {
                  label: "Online Seminar",
                  value: "Online Seminar",
                },
                {
                  label: "Seminar",
                  value: "Seminar",
                },
                {
                  label: "Training",
                  value: "Training",
                },
                {
                  label: "Training Online",
                  value: "Training Online",
                },
              ]}
              control={control}
            />
          </Grid>
          {/* dropdown Sector Name */}
          <Grid item xs={12} md={6} lg={3}>
            <FormInputDropdown
              label="Sector Name"
              name="sector_name"
              options={sectorOptions}
              control={control}
            />
          </Grid>

          {/* fron donor list */}
          <Grid item xs={12} md={6} lg={3}>
            <FormInputDropdown
              label="Dev Partner"
              name="dev_partner_name"
              options={donorOptions || []}
              control={control}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default SearchTrainingForm;
