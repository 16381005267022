import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface Props {
  rows: any[];
}

export default function EmpDataTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead
          sx={{
            background: "green",
          }}
        >
          <TableRow>
            <TableCell style={{ color: "#fff" }}>ID</TableCell>

            <TableCell align="left" style={{ color: "#fff" }}>
              Employee Name
            </TableCell>

            <TableCell align="left" style={{ color: "#fff" }}>
              Cnic
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Passport
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Gender
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              DOB
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Domicile
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Education
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Degree
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Department
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Present Designation
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Grade (BPS)
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Service Group
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.training_name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="left">{row.employee_name}</TableCell>
              <TableCell align="left">{row.cnic}</TableCell>
              <TableCell align="left">{row.passport}</TableCell>
              <TableCell align="left">{row.employee_gender}</TableCell>
              <TableCell align="left">{row.dob}</TableCell>
              <TableCell align="left">{row.domicile}</TableCell>
              <TableCell align="left">{row.education}</TableCell>
              <TableCell align="left">{row.degree}</TableCell>
              <TableCell align="left">{row.department_name}</TableCell>
              <TableCell align="left">{row.present_designation}</TableCell>
              <TableCell align="left">{row.grade_bps}</TableCell>
              <TableCell align="left">{row.service_group}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
