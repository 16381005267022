import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import Api, { APIs } from "../../../../Api";
import useQuery from "../../../base/utils/UseQuery";
import ComboBoxAutocomplete from "./ComboBoxAutocomplete";
import DatesDataTable from "./DatesDataTable";
import { FormDateInput } from "./FormDateInput";
import { FormInputRadio } from "./FormInputRadio";
import { FormInputText } from "./FormInputText";

type IFormInput = {
  training_name: string;
  training_type: any;
  start_date: string;
  end_date: string;
  status: string;
};

interface Props {
  trainingData: any;
}

function AddTrainingDatesFrom({ trainingData }: Props) {
  const query = useQuery();
  const training_id = query.get("training_id") as string;
  const [savedEmps, setSavedEmps] = useState<any[]>([]);
  const [editedValues, setEditedValues] = useState<any>(null);

  const { handleSubmit, control, setValue, reset } = useForm<IFormInput>({
    defaultValues: {
      training_name: trainingData?.training_name || "",
      training_type: null,
      start_date: "",
      end_date: "",
      status: "1",
    },
  });

  const onSubmit = (data: IFormInput) => {
    Api.post(APIs.SAVE_TRAINING_DATES, {
      data: [
        {
          ...data,
          pk: editedValues?.id || -1,
          op: editedValues ? "edited" : "added",
          training_id: training_id || trainingData?.id,
          training_type: data?.training_type?.title || "",
        },
      ],
    })
      .then((res) => {
        toastr.success("Success", "Data Saved Successfully");
        reset();
        if (training_id || trainingData) {
          getTraingdataById(+training_id || trainingData?.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (trainingData) {
      setValue("training_name", trainingData?.training_name);
    }
  }, [trainingData]);

  const getTraingdataById = (training_id: number) => {
    Api.getList(`ead/training/${training_id}/all/all/all/`)
      .then((data) => {
        if (data && data?.trainingDates && data?.trainingDates?.length > 0) {
          const training_emps = data?.trainingDates;
          setSavedEmps(training_emps);
          // handleEditData(training_emps[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (training_id || trainingData) {
      getTraingdataById(+training_id || trainingData?.id);
    }
  }, [training_id, trainingData]);

  const handleEditData = (emp_trining: any) => {
    setEditedValues(emp_trining);

    setValue("training_type", emp_trining?.training_type);
    setValue("training_name", emp_trining?.training_name);
    setValue("start_date", emp_trining?.start_date);
    setValue("end_date", emp_trining?.end_date);
  };

  console.log({ savedEmps });

  return (
    <>
      <div
        style={{
          maxWidth: "570px",
          margin: "0px auto",
        }}
      >
        <Typography variant="h5" style={{ marginTop: 20, marginBottom: 20 }}>
          Add Training Dates
        </Typography>

        <form>
          <Grid container spacing={[2]}>
            <Grid item xs={12}>
              <FormInputText
                name="training_name"
                control={control}
                label="Training Name"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <ComboBoxAutocomplete
                label="Training Type"
                name="training_type"
                control={control}
                optionList={savedEmps?.map((el) => ({
                  inputValue: el?.training_type,
                  title: el?.training_type,
                }))}
              />
              {/* <FormInputDropdown
                label="Training Type"
                name="training_type"
                options={[
                  {
                    label: "Online",
                    value: "Online",
                  },
                  {
                    label: "Physical",
                    value: "Physical",
                  },
                  {
                    label: "Online & Physical",
                    value: "Online & Physical",
                  },
                ]}
                control={control}
              /> */}
            </Grid>

            <Grid item xs={12} md={6}>
              <FormDateInput
                name="start_date"
                control={control}
                label="Start Date"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDateInput
                name="end_date"
                control={control}
                label="End Date"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputRadio
                name="status"
                label="Status"
                options={[
                  {
                    label: "Active",
                    value: "1",
                  },
                  {
                    label: "In-active",
                    value: "0",
                  },
                ]}
                control={control}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "right" }}>
              {editedValues && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    reset();
                    setEditedValues(null);
                    setValue("training_name", trainingData?.training_name);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <br />
      <DatesDataTable
        rows={savedEmps}
        editedId={editedValues?.id}
        handleEditData={handleEditData}
      />
    </>
  );
}

export default AddTrainingDatesFrom;
