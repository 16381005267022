import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Api, { APIs } from "../../../../Api";
import { FormDateInput } from "./FormDateInput";
import { FormInputDropdown } from "./FormInputDropdown";
import { FormInputRadio } from "./FormInputRadio";
import { FormInputText } from "./FormInputText";
import { FormInputTextarea } from "./FormInputTextarea";

import { toastr } from "react-redux-toastr";
import useQuery from "../../../base/utils/UseQuery";
import ComboBoxAutocomplete from "./ComboBoxAutocomplete";
import { FormNumberInput } from "./FormNumberInput";

export type IFormInput = {
  training_name: string;
  training_type: any;
  training_year: number | null;
  dev_partner_name: string;
  section_id: number;
  section_name: string;
  country: string;
  start_date: string;
  end_date: string;
  relevance_of_training: string;
  status: string;
  training_attended: string;
  last_ft_received_title: string;
  last_ft_received_date: string;
  remarks: string;
  confirmd: string;
};

interface Props {
  setTrainingData: (data: any) => void;
}

function AddTrainingFrom({ setTrainingData }: Props) {
  const query = useQuery();
  const training_id = query.get("training_id") as string;
  const { handleSubmit, control, setValue } = useForm<IFormInput>({
    defaultValues: {
      training_name: "",
      training_type: null,
      training_year: null,
      dev_partner_name: "",
      section_id: 1,
      section_name: "",
      country: "",
      start_date: "",
      end_date: "",
      relevance_of_training: "",
      status: "1",
      training_attended: "Yes",
      last_ft_received_title: "",
      last_ft_received_date: "",
      remarks: "",
      confirmd: "",
    },
  });

  const [donorOptions, setDonorOptions] = useState<any[]>([]);

  useEffect(() => {
    Api.get(APIs.EAD_UNIQUE_LIST).then((payload: any) => {
      if (payload?.donor && payload?.donor?.length > 0) {
        const donorList = payload?.donor?.map((item: string) => ({
          label: item,
          value: item,
        }));
        setDonorOptions(donorList);
      }
    });
  }, []);

  const onSubmit = (data: IFormInput) => {
    Api.post(APIs.SAVE_TRAINING, {
      data: [
        {
          ...data,
          pk: training_id || -1,
          op: training_id ? "edited" : "added",
          training_year: +(data?.training_year || 2023),
          training_type: data?.training_type?.title || "",
        },
      ],
    })
      .then((result) => {
        toastr.success("Success", "Data Saved Successfully");

        setTrainingData({ ...data, id: result?.last_id });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [trainingTypes, setTrainingTypes] = useState<any[]>([]);
  const [trainingSections, setTrainingSections] = useState<any[]>([]);

  const getAllTrainingList = () => {
    Api.getList(`ead/training/all/all/all/all/`)
      .then((data) => {
        if (data && data?.training && data?.training?.length > 0) {
          const training_data = data?.training;
          if (training_data && training_data?.length > 0) {
            const types: any[] = training_data?.map((el: any) => ({
              inputValue: el?.training_type,
              title: el?.training_type,
            }));
            const uniqueList: any[] = [];
            types.forEach((t) => {
              if (!uniqueList.find((el) => el?.title === t.title)) {
                uniqueList.push(t);
              }
            });
            setTrainingTypes(uniqueList);

            // section
            const secrtions: any[] = training_data?.map((el: any) => ({
              inputValue: el?.section_name,
              title: el?.section_name,
            }));
            const uniqueSectionList: any[] = [];
            secrtions.forEach((t) => {
              if (!uniqueSectionList.find((el) => el?.title === t.title)) {
                uniqueSectionList.push(t);
              }
            });
            setTrainingSections(uniqueSectionList);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllTrainingList();
  }, []);

  useEffect(() => {
    if (training_id) {
      Api.getList(`ead/training/${training_id}/all/all/all/`)
        .then((data) => {
          if (data && data?.training && data?.training?.length > 0) {
            const training_data = data?.training[0];
            setTrainingData(training_data);
            setValue("confirmd", training_data?.confirmd);
            setValue("country", training_data?.country);
            setValue("dev_partner_name", training_data?.dev_partner_name);
            setValue("end_date", training_data?.end_date);
            setValue(
              "last_ft_received_date",
              training_data?.last_ft_received_date
            );
            setValue(
              "last_ft_received_title",
              training_data?.last_ft_received_title
            );
            setValue(
              "relevance_of_training",
              training_data?.relevance_of_training
            );
            setValue("remarks", training_data?.remarks);
            setValue("section_name", training_data?.section_name);
            setValue("start_date", training_data?.start_date);
            setValue("status", training_data?.status);
            setValue("training_attended", training_data?.training_attended);
            setValue("training_name", training_data?.training_name);
            setValue("training_type", training_data?.training_type);
            setValue("training_year", training_data?.training_year);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [training_id, setValue]);

  return (
    <div
      style={{
        maxWidth: "570px",
        margin: "0px auto",
      }}
    >
      <Typography variant="h5" style={{ marginTop: 20, marginBottom: 20 }}>
        Add Training
      </Typography>

      <form>
        <Grid container spacing={[2]}>
          <Grid item xs={12} md={6}>
            <FormNumberInput
              name="training_year"
              control={control}
              label="Training Year"
            />
          </Grid>

          {/* dropdown */}
          <Grid item xs={12} md={6}>
            <ComboBoxAutocomplete
              label="Type"
              name="training_type"
              control={control}
              optionList={trainingTypes}
            />
            {/* <FormInputDropdown
              label="Type"
              name="training_type"
              options={[
                {
                  label: "Course",
                  value: "Course",
                },
                {
                  label: "Doctoral",
                  value: "Doctoral",
                },
                {
                  label: "Master",
                  value: "Master",
                },
                {
                  label: "Master & Doctoral",
                  value: "Master & Doctoral",
                },
                {
                  label: "Online & Physical Course",
                  value: "Online & Physical Course",
                },
                {
                  label: "Online Course",
                  value: "Online Course",
                },
                {
                  label: "Online Seminar",
                  value: "Online Seminar",
                },
                {
                  label: "Seminar",
                  value: "Seminar",
                },
                {
                  label: "Training",
                  value: "Training",
                },
                {
                  label: "Training Online",
                  value: "Training Online",
                },
              ]}
              control={control}
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <ComboBoxAutocomplete
              label="Section Name"
              name="section_name"
              control={control}
              optionList={trainingSections}
            />
            {/* <FormInputText
              name="section_name"
              control={control}
              label="Section Name"
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputText name="country" control={control} label="Country" />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name="training_name"
              control={control}
              label="Training Name"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDateInput
              name="start_date"
              control={control}
              label="Start Date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDateInput name="end_date" control={control} label="End Date" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputText
              name="relevance_of_training"
              control={control}
              label="Relevance of proposed training"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputDropdown
              label="Training Approved"
              name="training_approved"
              options={[
                {
                  label: "Yes",
                  value: "Yes",
                },
                {
                  label: "No",
                  value: "No",
                },
              ]}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputDropdown
              label="Training Attended"
              name="training_attended"
              options={[
                {
                  label: "Yes",
                  value: "Yes",
                },
                {
                  label: "No",
                  value: "No",
                },
              ]}
              control={control}
            />
          </Grid>

          {/* fron donor list */}
          <Grid item xs={12} md={6}>
            <FormInputDropdown
              label="Dev Partner"
              name="dev_partner_name"
              options={donorOptions || []}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInputText
              name="last_ft_received_title"
              control={control}
              label="Last FT Received Title"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDateInput
              name="last_ft_received_date"
              control={control}
              label="Last FT Received Date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputDropdown
              label="Confirmed"
              name="confirmd"
              options={[
                {
                  label: "Yes",
                  value: "Yes",
                },
                {
                  label: "No",
                  value: "No",
                },
              ]}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInputTextarea
              name="remarks"
              control={control}
              label="Remarks"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputRadio
              name="status"
              label="Status"
              options={[
                {
                  label: "Active",
                  value: "1",
                },
                {
                  label: "In-active",
                  value: "0",
                },
              ]}
              control={control}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              save
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default AddTrainingFrom;
