import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface Props {
  rows: any[];
  editedId?: number;
  handleEditData: (data: any) => void;
}

export default function EmpDataTable({
  rows,
  editedId,
  handleEditData,
}: Props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead
          sx={{
            background: "green",
          }}
        >
          <TableRow>
            <TableCell style={{ color: "#fff" }}>ID</TableCell>

            <TableCell align="left" style={{ color: "#fff" }}>
              Employee Name
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Present Designation
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Service Status
            </TableCell>
            <TableCell align="right" style={{ color: "#fff" }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.training_name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="left">{row.employee_name}</TableCell>
              <TableCell align="left">{row.present_designation}</TableCell>
              <TableCell align="left">{row.service_status}</TableCell>
              <TableCell align="right">
                {editedId === row?.id ? (
                  <>Edited</>
                ) : (
                  <IconButton onClick={() => handleEditData(row)}>
                    <Edit style={{ fontSize: 16 }} />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
