//@ts-ignore
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

//reducers
import { authReducer } from "./modules/auth/models/AuthSlice";
import { baseReducer } from "./modules/base/models/BaseSlice";
// import { snackbarReducer } from "./modules/base/models/SnackbarSlice";
import { copareReducer } from "./modules/dashboard/models/compare-slice";
import { dashboardReducer } from "./modules/dashboard/models/dashboard-slice";

const rootReducer = combineReducers({
  base: baseReducer,
  auth: authReducer,
  // snackbar: snackbarReducer,
  dashboard: dashboardReducer,
  compare: copareReducer,
  toastr: toastrReducer,
});

const persistConfig = {
  key: "ead-v2",
  version: 2,
  storage,
  whiteList: ["auth"],
  blackList: ["dashboard"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  //@ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([thunk]),
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
export default store;

// import {configureStore} from "@reduxjs/toolkit";
// import {authReducer} from "./modules/auth/models/AuthSlice";
// import {snackbarReducer} from "./modules/base/models/SnackbarSlice";
// import {dashboardReducer} from "./modules/dashboard/models/dashboard-slice";
// import {baseReducer} from "./modules/base/models/BaseSlice";
// import {copareReducer} from "./modules/dashboard/models/compare-slice";

// export const store = configureStore({
//     reducer: {
//         base: baseReducer,
//         auth: authReducer,
//         snackbar: snackbarReducer,
//         dashboard: dashboardReducer,
//         compare: copareReducer,
//     }
// });
