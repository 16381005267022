import { toastr } from "react-redux-toastr";
import { logoutUser } from "./modules/auth/models/AuthSlice";
import CommonUtils from "./modules/base/utils/CommonUtils";
import store from "./store";

export const APIs = Object.freeze({
  API_OAUTH_LOGIN: "api/jwt/oauth/login/{type}/",
  API_REFRESH_TOKEN: "api/jwt/refresh/",
  API_LOGIN: "api/jwt/auth/login/",
  EAD_PROJECTS: "ead/projects/{sector}/{region}/{donor}/{rating}/",
  EAD_COMPANY: "ead/company/{cid}/",
  EAD_SEARCH_PROJECTS:
    "ead/searchprojects/{sector}/{region}/{donor}/{rating}/{roles}/{pname}/{donortype}/",
  EAD_PROJECT_DELAY: "ead/project_delays/",
  EAD_PROJECT: "ead/project/{pid}/",
  EAD_USER_POSITION: "ead/user_position/",
  EAD_USER_PROJECTS: "ead/user_projects/",
  EAD_PROJECT_LOC: "ead/project_loc/{sector}/{region}/{donor}/{rating}/",
  EAD_PROVINCE_DIST: "ead/province_dist/{sector}/{region}/{donor}/{donortype}/",
  EAD_SECTORAL_DIST: "ead/sectoral_dist/{sector}/{region}/{donor}/{donortype}/",
  EAD_DONOR_DIST: "ead/donor_dist/{sector}/{region}/{donor}/{donortype}/",
  EAD_DONOR_DIST_RATING:
    "ead/donor_dist_rating/{sector}/{region}/{donor}/{rating}/{donortype}/",
  EAD_PROVINCIAL_DIST_RATING:
    "ead/province_dist_rating/{sector}/{region}/{donor}/{rating}/{donortype}/",
  EAD_SECTORAL_DIST_RATING:
    "ead/sectoral_dist_rating/{sector}/{region}/{donor}/{rating}/{donortype}/",
  EAD_PORTFOLIO: "ead/portfolio/{sector}/{region}/{donor}/{donortype}",
  EAD_UNIQUE_LIST: "ead/unique_list/",
  EAD_EDIT_FIN: "ead/save_project_fin/{pid}/",
  EAD_EDIT_PROGRESS: "ead/save_project_progress/{pid}/",
  EAD_EDIT_NOTES: "ead/save_projectnotes/{pid}/",
  EAD_EDIT_ISSUES: "ead/save_project_issues/{pid}/",
  EAD_EDIT_DECISION: "ead/save_project_decision/{pid}/",
  EAD_EDIT_DISBURSEMENT: "ead/save_project_disbursement/{pid}/",
  EAD_EDIT_WITHDRAWALS: "ead/save_project_withdrawal/{pid}/",
  EAD_NEWS_FEED: "ead/get_news_feed/",
  EAD_NEWS_COUNT: "ead/get_news_count/",
  EAD_SUB_PROJECTS: "ead/get_sub_projects/{pid}/",
  EAD_PROJECT_WBS: "ead/get_project_wbs/{spId}/",
  EAD_PROJECT_BASIC_INFO: "ead/get_project_basic_info/{pid}",
  EAD_PROJECT_PHOTOS: "ead/project_photos/{pid}/",
  EAD_PROJECT_VIDEOS: "ead/project_videos/{pid}/",
  EAD_PROJECT_DOCUMENTS: "ead/project_documents/{pid}/",
  EAD_PROJECT_DISBURSEMENT: "ead/get_project_disbursements/{pid}/",
  EAD_PROJECT_WITHDRAWALS: "ead/get_project_withdrawals/{pid}/",
  EAD_ALL_FILTERS: "ead/all_filters_list/",
  EAD_POSITIONS: "ead/positions/",
  JMC_SEARCH_PARAMS:
    "jmc/get_jmc_list/{jmctitle}/{jmccountry}/{fromdate}/{todate}/{jmctype}",
  JMC_UNIQUE_LIST: "jmc/get_jmc_unique_list",
  JMC_SEARCH: "jmc/get_jmc_search",
  JMC_DETAILS: "jmc/get_jmc_detail",
  ADD_PROJECTIONS: "ead/save_project_projection",
  GET_PROJECT_PROJECTION: "ead/get_project_projection",
  VERIFY_PROJECT: "ead/save_project_verification",
  SAVE_TRAINING: "ead/save_training/",
  SAVE_TRAINING_EMPLOYEE: "ead/save_trainingEmployee/",
  SAVE_TRAINING_DATES: "ead/save_trainingDates/",
  EAD_SEARCH_TRAINING: "ead/training/{id}/{type}/{year}/{country}/",
  EAD_SEARCH_TRAINING_RANGE:
    "ead/traininginrange/{devpartner}/{type}/{year}/{country}/{sector}/{startdate}/{enddate}/",
});

export default class Api {
  static getMediaURL(path) {
    return `${process.env.REACT_APP_API_URL}/media/${path}`;
  }

  static getURL(api, params = null) {
    const API_URL = process.env.REACT_APP_API_URL;
    let url = `${API_URL}/${api}`;
    for (const key in params) url = url.replace(`{${key}}`, params[key]);

    url = url.slice(-1) !== "/" ? url + "/" : url;
    return url;
  }

  static async getAccessToken() {
    try {
      const state = store.getState();
      const refreshToken = state.auth.refreshToken;
      if (refreshToken) {
        const url = Api.getURL(APIs.API_REFRESH_TOKEN);
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({
            refresh: refreshToken,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          // console.log("accessToken", data);
          return data.access;
        } else {
          CommonUtils.logoutUser();
          CommonUtils.showSnackbar("Please login to access data..");
        }
      }
    } catch (e) {
      CommonUtils.showSnackbar(
        "Failed to contact to server. Please ask system administrator."
      );
    }
  }

  static async get(apiKey, urlParams = {}, getParams = {}, isJSON = true) {
    // const accessToken = await this.getAccessToken(); //state.user.accessToken``
    const accessToken = store.getState().auth.accessToken;

    const headers = new Headers({
      Authorization: "Bearer " + accessToken,
    });

    let url = Api.getURL(apiKey, urlParams);
    let i = 0;
    for (let key in getParams) {
      url +=
        i === 0 ? `?${key}=${getParams[key]}` : `&${key}=${getParams[key]}`;
      i++;
    }
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: headers,
    });
    const res = await this.apiResponse(response, isJSON);

    return res && (res.payload || res?.List);
  }

  static async getList(apiKey, urlParams = {}, getParams = {}, isJSON = true) {
    // const accessToken = await this.getAccessToken(); //state.user.accessToken``
    const accessToken = store.getState().auth.accessToken;

    const headers = new Headers({
      Authorization: "Bearer " + accessToken,
    });

    let url = Api.getURL(apiKey, urlParams);
    let i = 0;
    for (let key in getParams) {
      url +=
        i === 0 ? `?${key}=${getParams[key]}` : `&${key}=${getParams[key]}`;
      i++;
    }
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: headers,
    });
    const res = await this.apiResponse(response, isJSON);

    return res;
  }

  static async post(apiKey, data, params = {}, isJSON = true) {
    try {
      // const state = store.getState();
      // const accessToken = await this.getAccessToken(); //state.user.accessToken
      const accessToken = store.getState().auth.accessToken;

      CommonUtils.showSnackbar("Posting data on server...");
      // const state = store.getState();
      const headers = new Headers({
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      });
      const url = Api.getURL(apiKey, params);
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: headers,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return await this.apiResponse(response, isJSON);
    } catch (e) {
      // CommonUtils.showSnackbar(
      //   "Services are not available at this time.",
      //   AlertType.error
      // );
      console.error(e);
    }
  }

  static async oauth_authenticate(data) {
    const url = Api.getURL(APIs.API_OAUTH_LOGIN, { type: "Google" });

    const headers = new Headers({
      // "Authorization": "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: headers,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return await this.apiResponse(response, true);
  }

  static async authenticate(formData) {
    try {
      const headers = new Headers({
        "X-REQUESTED-WITH": "XMLHttpRequest",
      });
      const url = Api.getURL(APIs.API_LOGIN);
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: headers,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formData, // body data type must match "Content-Type" header
      });
      return await this.apiResponse(response, true);
    } catch (e) {
      // CommonUtils.showSnackbar("Failed to authenticate user", AlertType.error);
      console.error(e);
    }
  }

  static async postFile(api_key, formData, params) {
    const accessToken = await this.getAccessToken();

    // let data = new FormData();
    // data.append("file", document.querySelector("#file-input").files[0]);
    return new Promise(function (resolve, reject) {
      // executor (the producing code, "singer")
      let request = new XMLHttpRequest();
      const url = Api.getURL(api_key, params);
      request.open("POST", url);
      request.setRequestHeader("Authorization", "Bearer " + accessToken);
      // request.setRequestHeader("'Access-Control-Allow-Origin'", "*");
      // upload progress event
      request.upload.addEventListener("progress", function (e) {
        // upload progress as percentage
        // let percent_completed = (e.loaded / e.total)*100;
        // CommonUtils.updateProgress(percent_completed);
      });

      // request finished event
      request.addEventListener("load", function () {
        // HTTP status message (200, 404 etc)
        console.log(request.status);
        // request.response holds response from the server
        if (request.status === 200) {
          const response = JSON.parse(request.response);
          resolve(response);
          console.log(response);
        } else {
          // CommonUtils.showSnackbar(
          //   "Upload Failed with errors",
          //   AlertType.error
          // );
          console.log("upload failed", request.response);
          reject("Upload Failed");
        }
        // CommonUtils.hideProgress();
      });
      request.addEventListener("error", () => {
        // CommonUtils.showSnackbar("Upload Failed", AlertType.error);
        console.log("upload failed", request.response);
        reject("Upload Failed");
        CommonUtils.hideProgress();
      });
      // send POST request to server
      request.send(formData);
    });
  }

  static downloadFile(apiKey, fileName, params = null) {
    try {
      this.getAccessToken().then((accessToken) => {
        if (accessToken) {
          const headers = new Headers({
            Authorization: "Bearer " + accessToken,
          });
          const url = Api.getURL(apiKey, params);
          fetch(url, {
            method: "GET",
            mode: "cors",
            credentials: "same-origin",
            headers: headers,
          })
            .then((transfer) => {
              return transfer.blob(); // RETURN DATA TRANSFERED AS BLOB
            })
            .then((bytes) => {
              let elm = document.createElement("a"); // CREATE A LINK ELEMENT IN DOM
              elm.href = URL.createObjectURL(bytes); // SET LINK ELEMENTS CONTENTS
              elm.setAttribute("download", fileName); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
              elm.click(); // TRIGGER ELEMENT TO DOWNLOAD
            })
            .catch((error) => {
              console.log(error); // OUTPUT ERRORS, SUCH AS CORS WHEN TESTING NON LOCALLY
            });
        }
      });
      // const state = store.getState();
    } catch (e) {
      // CommonUtils.showSnackbar(
      //   "Services are not available at this time.",
      //   AlertType.error
      // );
      console.error(e);
    }
  }

  // static async postFile (api_key, formData, isJSON = true) {
  //   try {
  //     const accessToken = await this.getAccessToken();
  //     const headers = new Headers({
  //       "X-REQUESTED-WITH": "XMLHttpRequest",
  //       "Authorization": "Bearer " + accessToken
  //     });
  //     const url = Api.getURL(api_key);
  //     const response = await fetch(url, {
  //       method: "POST",
  //       mode: "cors",
  //       cache: "no-cache",
  //       credentials: "same-origin",
  //       headers: headers,
  //       redirect: "follow", // manual, *follow, error
  //       referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //       body: formData // body data type must match "Content-Type" header
  //     });
  //     return await this.apiResponsePayload(response, isJSON);
  //   } catch (e) {
  //     CommonUtils.showSnackbar("Failed to upload files", AlertType.error);
  //     console.error(e);
  //   }
  // }

  static async apiResponse(response, isJSON = true) {
    if (response.ok)
      return isJSON ? await response.json() : await response.text();
    // else if (response.status === 401)
    //   // CommonUtils.showSnackbar(
    //   //   "You are unauthorized to submit this request. Please contact project office.",
    //   //   AlertType.error
    //   // );
    // // store.dispatch(setAuthentication(false));
    else if (response.status === 400)
      CommonUtils.showSnackbar("Bad Request. Please check your parameters...");
    // else if (response.status === 204)
    //   // CommonUtils.showSnackbar(
    //   //   "No related data or content found",
    //   //   AlertType.error
    //   // );
    else if (response.status === 500) {
      // token expired
      // CommonUtils.showSnackbar(
      //   "Session expired. Please login again.",
      //   AlertType.error
      // );
      setTimeout(() => {
        store.dispatch(logoutUser());
        window.location.replace("/");
      }, 2000);
    } else {
      // CommonUtils.showSnackbar(
      //   "Failed to post service. Please contact admin",
      //   AlertType.error
      // );

      toastr.error("Error", "Something went wrong!");
    }
    return null;
  }
}
