import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface Props {
  rows: any[];
}

export default function DatesDataTable({ rows }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead
          sx={{
            background: "green",
          }}
        >
          <TableRow>
            <TableCell style={{ color: "#fff" }}>ID</TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Training Type
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              Start Date
            </TableCell>
            <TableCell align="left" style={{ color: "#fff" }}>
              End Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.training_name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="left">{row.training_type}</TableCell>
              <TableCell align="left">{row.start_date}</TableCell>
              <TableCell align="left">{row.end_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
