import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import Api, { APIs } from "../../../../Api";
import useQuery from "../../../base/utils/UseQuery";
import EmpDataTable from "./EmpDataTable";
import { FormDateInput } from "./FormDateInput";
import { FormInputDropdown } from "./FormInputDropdown";
import { FormInputText } from "./FormInputText";
import { FormInputTextarea } from "./FormInputTextarea";
import { FormNumberInput } from "./FormNumberInput";

type IFormInput = {
  training_name: string;
  employee_name: string;
  cnic: string;
  passport: string;
  employee_gender: string;
  dob: string;
  domicile: string;
  education: string;
  degree: string;
  present_posting_date: string;
  present_responsibilities: string;
  english_language_test_name: string;
  department_name: string;
  present_designation: string;
  grade_bps: number | null;
  service_group: string;
  service_status: string;
  joining_date_govt: string;
};

interface Props {
  trainingData: any;
}

function AddTrainingEmployeeFrom({ trainingData }: Props) {
  const query = useQuery();
  const training_id = query.get("training_id") as string;
  const [savedEmps, setSavedEmps] = useState<any[]>([]);
  const [editedValues, setEditedValues] = useState<any>(null);

  const { handleSubmit, control, setValue, reset } = useForm<IFormInput>({
    defaultValues: {
      training_name: trainingData?.training_name || "",
      employee_name: "",
      cnic: "",
      passport: "",
      employee_gender: "",
      dob: "",
      domicile: "",
      education: "",
      degree: "",
      present_posting_date: "",
      present_responsibilities: "",
      english_language_test_name: "",
      department_name: "",
      present_designation: "",
      grade_bps: null,
      service_group: "",
      service_status: "",
      joining_date_govt: "",
    },
  });

  useEffect(() => {
    if (trainingData) {
      setValue("training_name", trainingData?.training_name);
    }
  }, [trainingData]);

  const onSubmit = (data: IFormInput) => {
    Api.post(APIs.SAVE_TRAINING_EMPLOYEE, {
      data: [
        {
          ...data,
          pk: editedValues?.id || -1,
          op: editedValues ? "edited" : "added",
          training_id: training_id || trainingData?.id,
        },
      ],
    })
      .then((res) => {
        console.log(res.data);
        toastr.success("Success", "Data Saved Successfully");
        if (training_id || trainingData) {
          getTraingdataById(+training_id || trainingData?.id);
        }
        reset();
        setEditedValues(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTraingdataById = (training_id: number) => {
    Api.getList(`ead/training/${training_id}/all/all/all/`)
      .then((data) => {
        if (
          data &&
          data?.trainingEmployee &&
          data?.trainingEmployee?.length > 0
        ) {
          const training_emps = data?.trainingEmployee;
          setSavedEmps(training_emps);
          // handleEditData(training_emps[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (training_id) {
      getTraingdataById(+training_id);
    }
  }, [training_id]);

  const handleEditData = (emp_trining: any) => {
    setEditedValues(emp_trining);

    setValue("cnic", emp_trining?.cnic);
    setValue("degree", emp_trining?.degree);
    setValue("department_name", emp_trining?.department_name);
    setValue("dob", emp_trining?.dob);
    setValue("domicile", emp_trining?.domicile);
    setValue("education", emp_trining?.education);
    setValue("employee_gender", emp_trining?.employee_gender);
    setValue("employee_name", emp_trining?.employee_name);
    setValue(
      "english_language_test_name",
      emp_trining?.english_language_test_name
    );
    setValue("grade_bps", emp_trining?.grade_bps);
    setValue("joining_date_govt", emp_trining?.joining_date_govt);
    setValue("passport", emp_trining?.passport);
    setValue("present_designation", emp_trining?.present_designation);
    setValue("present_posting_date", emp_trining?.present_posting_date);
    setValue("present_responsibilities", emp_trining?.present_responsibilities);
    setValue("service_group", emp_trining?.service_group);
    setValue("service_status", emp_trining?.service_status);
    setValue("training_name", emp_trining?.training_name);
  };

  // useEffect(() => {
  //   Api.getList(`ead/trainingemployee/1/`)
  //     .then((data) => {
  //       if (data && data?.payload && data?.payload?.length > 0) {
  //         const emp_trining = data?.payload[0];

  //         setEditedValues(emp_trining);

  //         setValue("cnic", emp_trining?.cnic);
  //         setValue("degree", emp_trining?.degree);
  //         setValue("department_name", emp_trining?.department_name);
  //         setValue("dob", emp_trining?.dob);
  //         setValue("domicile", emp_trining?.domicile);
  //         setValue("education", emp_trining?.education);
  //         setValue("employee_gender", emp_trining?.employee_gender);
  //         setValue("employee_name", emp_trining?.employee_name);
  //         setValue(
  //           "english_language_test_name",
  //           emp_trining?.english_language_test_name
  //         );
  //         setValue("grade_bps", emp_trining?.grade_bps);
  //         setValue("joining_date_govt", emp_trining?.joining_date_govt);
  //         setValue("passport", emp_trining?.passport);
  //         setValue("present_designation", emp_trining?.present_designation);
  //         setValue("present_posting_date", emp_trining?.present_posting_date);
  //         setValue(
  //           "present_responsibilities",
  //           emp_trining?.present_responsibilities
  //         );
  //         setValue("service_group", emp_trining?.service_group);
  //         setValue("service_status", emp_trining?.service_status);
  //         setValue("training_name", emp_trining?.training_name);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <>
      <div
        style={{
          maxWidth: "570px",
          margin: "0px auto",
        }}
      >
        <Typography variant="h5" style={{ marginTop: 20, marginBottom: 20 }}>
          Add Training Employee
        </Typography>

        <form>
          <Grid container spacing={[2]}>
            <Grid item xs={12}>
              <FormInputText
                name="training_name"
                control={control}
                label="Training Name"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="employee_name"
                control={control}
                label="Employee Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText name="cnic" control={control} label="Cnic" />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="passport"
                control={control}
                label="Passport"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputDropdown
                label="Gender"
                name="employee_gender"
                options={[
                  {
                    label: "Male",
                    value: "Male",
                  },
                  {
                    label: "Female",
                    value: "Female",
                  },
                  {
                    label: "Other",
                    value: "Other",
                  },
                ]}
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDateInput
                name="dob"
                control={control}
                label="Date of birth"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="domicile"
                control={control}
                label="Domicile"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="english_language_test_name"
                control={control}
                label="English Language Test Name"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="education"
                control={control}
                label="Education"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText name="degree" control={control} label="Degree" />
            </Grid>

            <Grid item xs={12}>
              <FormInputText
                name="department_name"
                control={control}
                label="Department Name"
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="present_designation"
                control={control}
                label="Present Designation"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormNumberInput
                name="grade_bps"
                control={control}
                label="Grade Bps"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                label="Service Group"
                name="service_group"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                label="Service Status"
                name="service_status"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDateInput
                label="Date of Joining Govt Service"
                name="joining_date_govt"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDateInput
                label="Date of Present Posting"
                name="present_posting_date"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputTextarea
                label="Present Responsibilities"
                name="present_responsibilities"
                control={control}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {editedValues && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    reset();
                    setEditedValues(null);
                    setValue("training_name", trainingData?.training_name);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>{" "}
      <br />
      {/* <DataTable /> */}
      <EmpDataTable
        rows={savedEmps}
        editedId={editedValues?.id}
        handleEditData={handleEditData}
      />
    </>
  );
}

export default AddTrainingEmployeeFrom;
